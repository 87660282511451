$colors: 'error', 'success', 'primary';

.Button {
  border-radius: 3px;
  max-width: 275px;
  width: 100%;
  min-width: 0;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  margin: 3px;
  padding: 0 1em;
  background: transparent
    linear-gradient(
      180deg,
      var(--button-start-primary) 0%,
      var(--button-end-primary) 100%
    )
    0% 0% no-repeat padding-box;
  color: var(--on-primary);

  @each $color in $colors {
    &--color-#{$color} {
      background: transparent
        linear-gradient(
          180deg,
          var(--button-start-#{$color}) 0%,
          var(--button-end-#{$color}) 100%
        )
        0%
        0%
        no-repeat
        padding-box;
      &__outlined {
        background: none;
        border: 2px solid var(--button-start-#{$color});
        color: var(--button-start-#{$color});
      }
    }
  }
}
