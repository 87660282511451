.drawer {
  padding: 0.9375rem;
  height: 100%;

  &__content {
    height: 100%;
    .list {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: 255px;
    }
  }
}
