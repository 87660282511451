.Dropdown {
  border-radius: 3px;
  background-color: hsl(26, 92%, 52%);
  color: hsl(0, 0%, 100%);

  &:active,
  &:focus {
    outline: none;
    border: none;
    background-color: hsla(26, 88%, 45%, 1);
  }

  &__title {
    background: inherit;
    font: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    border: none;
    font-weight: 700;
    min-height: 1em;
    display: inline-block;

    &::placeholder {
      color: inherit;
    }
  }

  &__inner {
    padding: 10px 20px;
    position: relative;
  }

  &__options {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 1em);
    height: 0;
    transition: 0.3s;
    z-index: 10;

    a:hover,
    a:focus {
      text-decoration: none;
      outline: none;
      border: none;
    }
  }

  &__option {
    padding: 10px 20px;
    background-color: hsla(26, 92%, 52%, 1);
    height: 0;
    pointer-events: none;
    transition: 0.3s;
    display: none;

    &:hover,
    &:focus {
      background-color: hsla(26, 88%, 45%, 1);
      border: none;
      outline: none;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 10px;
    height: 100%;
    object-fit: contain;
  }

  &.Dropdown--plain.wide {
    max-width: 100%;
    min-width: 0;
  }

  &.Dropdown-is-expanded {
    background-color: hsla(26, 88%, 45%, 1);

    .Dropdown__options {
      height: max-content;
    }

    .Dropdown__option {
      pointer-events: all;
      height: auto;
      min-height: calc(20px + 1.5em);
      transition: 0.3s;
      display: block;
    }
  }

  a.Dropdown__option {
    color: hsl(0, 0%, 100%);

    &:hover {
      text-decoration: none;
    }
  }

  &.Dropdown--plain .Dropdown__option {
    background-color: hsl(0, 0%, 100%);
    font-weight: 400;
  }

  &.Dropdown--blue {
    background-color: hsla(210, 100%, 22%, 1);

    &:active,
    &:focus {
      background-color: hsla(210, 87%, 12%, 1);
    }

    .Dropdown__option {
      background-color: hsla(210, 100%, 22%, 1);

      &:active,
      &:hover {
        background-color: hsla(210, 87%, 12%, 1);
      }
    }
  }

  &.Dropdown--primary {
    background: transparent
      linear-gradient(
        180deg,
        var(--button-start-primary) 0%,
        var(--button-end-primary) 100%
      )
      0% 0% no-repeat padding-box;
    color: var(--on-primary);

    .Dropdown__option {
      background-color: var(--button-start-primary);

      &:active,
      &:hover {
        background-color: var(--button-end-primary);
      }
    }
  }

  &.Dropdown--success {
    background: transparent
      linear-gradient(
        180deg,
        var(--button-start-success) 0%,
        var(--button-end-success) 100%
      )
      0% 0% no-repeat padding-box;
    color: var(--on-primary);

    .Dropdown__option {
      background-color: var(--button-start-success);

      &:active,
      &:hover {
        background-color: var(--button-end-success);
      }
    }
  }

  &.Dropdown--plain {
    background-color: hsl(0, 0%, 100%);
    color: hsla(0, 0%, 0%, 0.56);
    box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.24);
    font-weight: 400;
    max-width: 100%;
    min-width: 0;

    .Dropdown__title {
      background: inherit;
      font: inherit;
      font-weight: 400;
      color: inherit;
      margin: 0;
      padding: 0;
      border: none;

      &::placeholder {
        color: inherit;
      }
    }

    .Dropdown__options {
      background-color: hsl(0, 0%, 100%);
    }

    .Dropdown__inner {
      padding: 10px 20px;
      background-color: hsl(0, 0%, 100%);
    }
  }

  &.Dropdown-is-expanded.Dropdown--plain {
    background-color: hsl(0, 0%, 100%);

    &:active,
    &:focus {
      outline: none;
      border: none;
      background-color: hsla(0, 0%, 100%, 1);
    }

    .Dropdown__option {
      background-color: hsl(0, 0%, 100%);
      cursor: pointer;

      &:hover {
        background-color: hsl(0, 0%, 86%);
        font-weight: 700;
      }
    }

    .Dropdown__options {
      // min-height: 200px;
      max-height: 287px;
      overflow-y: auto;
      box-shadow: 0 6px 16px hsla(240, 13%, 15%, 0.16);
    }
  }

  span.Dropdown__option {
    color: hsl(0, 0%, 100%);
  }
}
