.AccountItem {
  background: transparent
    linear-gradient(180deg, var(--background) 0%, var(--A-background) 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  flex-wrap: wrap;
  padding: 1.25rem 24px;
  margin-bottom: 12px;

  &__flag {
    width: 28px;
    height: 28px;
    margin-right: 14px;

    @media screen and (min-width: 1250px) {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
    }
  }

  &__highlight {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;

    @media screen and (min-width: 1250px) {
      font-size: 1.5rem;
      line-height: 1.625rem;
    }
  }

  &__fade {
    color: hsl(0, 0%, 62%);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    display: inline-block;
    margin-left: 6px;
    margin-bottom: -4px;

    @media screen and (min-width: 992px) {
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__currency {
    width: 50%;
    display: flex;
    align-items: center;

    @media screen and (min-width: 1250px) {
      width: 25%;
    }
  }

  &__value {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: flex;

    @media screen and (min-width: 1250px) {
      width: 25%;
    }
  }

  &__buttons {
    width: 100%;
    min-width: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (min-width: 1250px) {
      width: 50%;
    }

    @media screen and (max-width: 1250px) {
      margin-top: 1rem;
    }

    a {
      width: calc(50% - 6px);
      height: 38px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: var(--link);
      text-decoration: none;
      cursor: pointer;
      font-weight: 700;
      margin: 3px;

      @media screen and (min-width: 1250px) {
        width: calc(50% - 28px);
        margin-right: 14px;
      }

      @media screen and (min-width: 1440px) {
        width: auto;
      }

      &:hover,
      &:active {
        text-decoration: none;
        color: hsl(0, 0%, 100%);
      }
    }

    a:hover {
      text-decoration: none;
    }
  }

  &__link {
    width: 100%;
    min-width: 0;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: hsl(0, 0%, 100%);
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    margin: 3px;
    padding: 0 1em;

    @media screen and (min-width: 992px) {
      //width: calc(50% - 28px);
      //margin-right: 14px;
    }

    @media screen and (min-width: 1440px) {
      //width: 88px;
    }

    &:hover,
    &:active {
      text-decoration: none;
      color: hsl(0, 0%, 100%);
    }

    &.AccountItem__link--success {
      background: transparent
        linear-gradient(
          180deg,
          var(--button-start-success) 0%,
          var(--button-end-success) 100%
        )
        0% 0% no-repeat padding-box;
      color: var(--on-primary);
    }

    &.AccountItem__link--blue {
      background-color: hsl(210, 100%, 23%);
    }
  }
}
