/* stylelint-disable */

html, body, #root {
  height: 100%;
}

* {
  outline: none !important;
}

select {
  appearance: none;
  appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
}

input {
  width: 100%;
  max-width: 100%;
}

input:not([type="checkbox"]):not([type="radio"]), select, textarea {
  appearance: none;
}

/* stylelint-enable */
