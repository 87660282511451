.Summary {
  &__row {
    background: transparent
      linear-gradient(180deg, var(--background) 0%, var(--A-background) 100%) 0%
      0% no-repeat padding-box;
    padding-top: 45px;
    padding-left: 78px;
    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &__item {
    min-width: 138px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 78px;
    margin-bottom: 45px;
  }

  &__title {
    font-weight: 700;
    color: var(--primary);
    font-size: 14px;
  }

  &__value {
    font-size: 22px;
  }

  &.Summary--blue {
    .Summary__title {
      color: var(--primary);
    }
  }
}
