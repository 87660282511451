.ModalButton {
  border-radius: 3px;
  width: 100%;
  min-width: 0;
  display: inline-block;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.1875rem;
  position: relative;
  padding: 15px 17px;
  cursor: pointer;
  margin-bottom: 1.5rem;
  text-align: center;
  background: transparent
    linear-gradient(
      180deg,
      var(--button-start-primary) 0%,
      var(--button-end-primary) 100%
    )
    0% 0% no-repeat padding-box;
  color: var(--on-primary);

  &__icon {
    margin-right: 18px;
    float: left;
    fill: var(--on-primary);
    stroke: var(--on-primary);

    @media screen and (min-width: 768px) {
      float: none;
    }
  }

  & + & {
    @media screen and (min-width: 768px) {
      margin-left: 1.5rem;
    }
  }

  @media screen and (min-width: 768px) {
    width: auto;
  }
}
