.HistoryIndex {
  &__button-export {
    background-color: var(--background);
    text-transform: uppercase;
    border: none;
    outline: none;
    box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.4);
    font-weight: 700;
    width: 100%;
    min-width: 0;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 0 auto;

    @media screen and (min-width: 768px) {
      width: 272px;
      margin-top: 100px;
    }
  }

  &__form {
    background-color: var(--background);
    color: var(--primary);
    padding: 1.6875rem 0.8125rem;
    margin-bottom: 1rem;

    input:not(.Dropdown__title),
    select {
      background-color: hsl(0, 0%, 100%);
      border-radius: 0;
      border: none;
      color: hsla(0, 0%, 0%, 0.56);
      box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.4);
      padding: 0.625rem;
      margin-top: 0.375rem;
      line-height: 1.5rem;
      font-size: 1rem;
      width: 100%;
      min-width: 0;
      min-width: 0;
    }
  }

  &__form-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    align-items: flex-end;
    justify-items: flex-start;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &--buttons {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__label {
    display: inline-flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    width: 100%;
    min-width: 0;
    margin: 0;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  &__form input[type='submit'] {
    border: none;
    border-radius: 3px;
    outline: none;
    background: transparent
      linear-gradient(
        180deg,
        var(--button-start-primary) 0%,
        var(--button-end-primary) 100%
      )
      0% 0% no-repeat padding-box;
    color: var(--on-primary);
    text-transform: uppercase;
    font-weight: 700;
    width: auto;
    height: auto;

    @media screen and (min-width: 768px) {
      width: 144px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 30%;
    }
  }

  &__date-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
  }

  &__sort {
    color: var(--primary);
    line-height: 2rem;
    margin-bottom: 0.875rem;
    font-size: 0.875rem;
    select {
      width: 135px;
      height: 35px;
      margin-left: 6px;
      border-radius: 0;
      border: none;
      background-color: hsl(0, 0%, 100%);
      box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.4);

      &:active,
      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  &__table {
    margin-top: 13px;
  }
}
