// default is dark theme
:root,
body.dark-theme {
  --top-color: #ffbc00;
  --on-top-color: #000000;
  --primary: hsl(28, 82%, 50%);
  --hover-primary: #ee7219;
  --on-primary: #ffffff;
  --secondary: hsl(220, 59%, 29%);
  --on-secondary: hsl(0, 0%, 100%);
  --background: #202020;
  --on-background: #ffffff;
  --2-background: #000000;
  --A-background: #161615;
  --on-A-background: #9d9d9d;
  --error-background: #441917;
  --on-error-background: #ffffff;
  --surface: hsl(0, 0%, 100%);
  --on-surface: hsla(0, 0%, 0%, 0.87);
  --error: #dc221b;
  --on-error: hsl(0, 0%, 100%);
  --dark-success: #046737;
  --light-success: #008942;
  --on-success: hsl(0, 0%, 100%);
  --icon: #ee7219;
  --primary-icon: #f67914;
  --on-primary-icon: #ffffff;
  --active-icon: #ee7219;
  --button-start-primary: #ee7219;
  --button-start-primary-hover: #C35505;
  --button-start-secondary: #FFC832;
  --button-end-primary: #f39313;
  --button-start-error: #af251a;
  --button-end-error: #e4221a;
  --button-start-success: #046737;
  --button-end-success: #008942;
  --link: #ffffff;
  --hover-link: hsl(28, 82%, 50%);
  --success-toast: 109, 185, 12;
  --error-toast: 255, 50, 13;
  --info-toast: 255, 188, 0;
  --info-text: #000000;
}

body.light-theme {
  --top-color: #003c72;
  --on-top-color: #f39313;
  --background: #ffffff;
  --on-background: #1d1d1d;
  --2-background: #a8a8a8;
  --A-background: #f5f5f5;
  --primary: #f39313;
  --on-primary: #f2f2f2;
  --link: #003c72;
  --hover-link: #f39313;
  --icon: #ee7219;
  --primary-icon: #f67914;
  --on-primary-icon: #1d1d1d;
}