.SettingsIndex {
  &__button {
    border-radius: 3px;
    display: none;
    width: 100%;
    min-width: 0;
    margin-top: 1.5rem;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border: none;

    &:not(:disabled) {
      background: transparent
        linear-gradient(
          180deg,
          var(--button-start-success) 0%,
          var(--button-end-success) 100%
        )
        0% 0% no-repeat padding-box;
      color: var(--on-primary);
    }

    @media screen and (min-width: 768px) {
      margin-top: 2.5rem;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &.SettingsIndex__button--visible {
      display: block;
    }

    @media screen and (min-width: 768px) {
      max-width: 278px;
      margin-left: auto;
    }
  }

  &__tab-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__nav-item {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    color: var(--on-background);
    width: 100%;
    min-width: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    cursor: pointer;
    background-color: var(--background);
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 5px solid var(--primary);
    transition: 0.3s;
    transition-property: color border-bottom;

    @media screen and (min-width: 768px) {
      font-size: 1.125rem;
      line-height: 1.3125rem;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &.SettingsIndex__nav-item--active {
      color: var(--on-background);
      background-color: var(--primary);
      border-bottom: 5px solid var(--primary);
      transition: 0.3s;
      transition-property: color border-bottom;
    }
  }

  &__form {
    width: 100%;
    min-width: 0;
    margin: 0 auto;
    padding: 0 0.75rem;

    @media screen and (min-width: 768px) {
      max-width: 363px;
      padding: 0;
    }
  }

  &__pw-label {
    color: var(--primary);
    width: 100%;
  }

  &__highlight {
    display: block;
    text-align: center;
    color: hsl(210, 100%, 23%);
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 0.375rem;
    width: 100%;
    min-width: 0;

    @media screen and (min-width: 768px) {
      display: inline-block;
    }
  }

  &__radio-label {
    display: inline-block;
    width: calc(50% - 0.75rem);
    text-align: center;

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }

  &__radio-button {
    display: inline-block;
    margin-left: 0.75rem;
  }

  &__pw-input {
    border: none;
    outline: none;
    box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.2);
    width: 100%;
    min-width: 0;
    padding: 10px 13px;
    margin-bottom: 40px;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  &__tab-section {
    background-color: var(--background);
    box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.3);
    padding: 0;
    width: 100%;
    min-width: 0;

    @media screen and (min-width: 768px) {
      min-height: 501px;
    }
  }

  &__tab {
    display: none;
    opacity: 0;
    height: 0;
    transition: 2.3s;
    transition-property: opacity;
    margin-top: 1.5rem;

    @media screen and (min-width: 768px) {
      margin-top: 5.25rem;
    }

    &.SettingsIndex__tab--visible {
      display: block;
      opacity: 1;
      height: auto;
    }
  }
}
