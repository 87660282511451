.UserMenu {
  position: absolute;
  right: 0;
  top: calc(100% + 1rem);
  padding: 25px 25px 50px 25px;
  box-shadow: 0 6px 16px hsla(240, 13%, 15%, 0.16);
  text-align: left;
  background: var(--background);
  min-width: 284px;
  z-index: 9999;

  &__inner {
    display: grid;
    grid-gap: 22px;
  }

  &__item {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;

    &--grow {
      justify-content: space-between;
    }

    &--paginator {
      font-weight: 700;
      margin-bottom: 10px;
    }

    svg {
      fill: var(--primary);
    }
  }

  .UserIcon {
    width: 100%;
    min-width: 0;

    &__inner {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
    }

    &__username {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }

    &__icon {
      margin-right: 20px;
    }
  }
}
