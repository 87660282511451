.Progress {
  padding: 19px 0;
  background-color: var(--top-color);
  margin: calc(-1.5625rem - 2px) -0.9375rem 40px -0.9375rem;

  @media screen and (min-width: 768px) {
    margin: calc(-1.5625rem - 2px) -3.75rem 40px -3.75rem;
  }

  &__list {
    list-style-type: none;
    color: hsla(0, 0%, 100%, 0.65);
    counter-reset: circle-numbers;
    margin-bottom: 0;
    padding: 0;

    @media screen and (min-width: 768px) {
      padding: 0 3.75rem;
    }
  }

  &__checkmark {
    padding-right: 20px;
    display: none;

    @media screen and (min-width: 768px) {
      display: inline-block;
    }
  }

  &__item {
    text-transform: uppercase;
    position: relative;
    counter-increment: circle-numbers;
    padding-left: 36px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    display: none;
    margin: 0 0.9375rem;
    color: var(--on-top-color);
    opacity: 50%;

    @media screen and (min-width: 1440px) {
      display: inline-block;
      margin-left: 40px;
      margin-right: 36px;
    }

    &::before {
      content: counter(circle-numbers);
      display: none;
      position: absolute;
      left: 0;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--on-top-color);
      border-radius: 50%;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 1px;
      background-color: var(--on-top-color);
      color: var(--on-primary);

      @media screen and (min-width: 1440px) {
        display: inline-flex;
      }
    }

    &::after {
      content: '';
      display: none;
      height: 2px;
      background-color: var(--on-top-color);
      width: 40px;
      position: absolute;
      top: 10px;
      left: -55px;

      @media screen and (min-width: 1440px) {
        display: block;
      }
    }

    &:first-child {
      margin-left: 0;
      &::after {
        display: none;
      }
    }
  }

  &__item--active {
    opacity: 100%;

    &::before {
      color: var(--primary);
      border: 2px solid var(--primary);
      background-color: inherit;
    }
  }

  &__item--completed {
    &::before {
      color: var(--dark-success);
      border: 2px solid var(--dark-success);
      background-color: inherit;
    }
  }

  &__item--active.Progress__item--mobileActive {
    display: block;

    @media screen and (max-width: 1339px) {
      padding: 0;
      margin: 0;
      text-align: center;
    }

    @media screen and (min-width: 1440px) {
      display: inline-block;
    }
  }

  &.Progress--buy {
    background-color: var(--top-color);
  }
}
