.AppLayout {
  display: flex;
  height: 100%;
  flex-direction: column;

  &.collapsed {
    width: 0;
    padding: 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    transition-property: width;
  }

  &__content {
    display: grid;
    height: 100%;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto;
    position: relative;
    overflow: auto;

    /* stylelint-disable */
    & > * {
      min-width: 0;
    }
    /* stylelint-enable */

    &--hidden {
      grid-template-columns: 1fr;
    }
  }

  &__container {
    height: 100%;
    width: 100%; min-width: 0;
    background-color: var(--A-background);
    overflow-y: auto;
  }

  &__main-content {
    height: 100%;
  }

  .mdc-top-app-bar {
    z-index: 7;
  }

  &__menu-toggler {
    width: 35px;
    height: 25px;
    top: calc(0.9375rem + 5px);
    right: 0.9375rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:active,
    &:focus {
      outline: none;
      border: none;
    }

    @media screen and (min-width: 992px) {
      display: none;
    }

    &--show {
      display: flex;
    }
  }

  &__toggler-bar {
    height: 5px;
    width: 100%; min-width: 0;
    background-color: hsl(27, 93%, 52%);
    align-self: flex-end;
    transition: 0.2s linear;
  }

  &__menu-toggler.AppLayout__menu-toggler--expanded {
    .AppLayout__toggler-bar:nth-child(2) {
      width: 66%;
    }
    .AppLayout__toggler-bar:nth-child(3) {
      width: 33%;
    }

    /* stylelint-disable */
    display: flex !important;
    /* stylelint-enable */
  }
}

main.AppLayout__container.logged-out {
  overflow: hidden;
  position: fixed;
  width: 60vw;
  left: calc(50% - 30vw);
  height: 70vh;
  top: calc(50% - 40vh);
}
