.Warning {
  width: 100%;
  min-width: 0;
  color: var(--on-error-background);
  display: flex;
  margin-bottom: 60px;

  &__icon-wrapper {
    background-color: var(--error);
    width: 64px;
    min-height: 64px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 64px;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
  }

  &__highlight {
    color: var(--error);
    display: inline-block;
    font-weight: 700;
    margin-right: 0.5em;
  }

  &__text {
    background-color: var(--error-background);
    display: inline-block;
    padding: 7px 15px;
    justify-content: flex-start;
    align-items: center;
  }
}
