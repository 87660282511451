.NavLink {
  display: block;
  margin-bottom: 0.625rem;
  cursor: pointer;
  padding: 0.5rem 1.09375rem;

  &__icon--active {
    display: none;
  }

  &__text {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--on-background);
    display: inline-block;
    margin-left: 1.6875rem;
  }

  &__icon {
    width: 1.125rem;
    height: 1rem;
    object-fit: contain;
    margin-right: 10px;
  }

  &.NavLink:hover,
  &.NavLink--activated {
    background-color: hsla(27, 93%, 52%, 0.1);
    .NavLink__icon--active {
      display: inline;
      width: 1.125rem;
      height: 1rem;
      object-fit: contain;
      margin-left: 10px;
    }

    .NavLink__icon {
      display: none;
    }
    .NavLink__text {
      color: var(--primary);
    }
  }
}
