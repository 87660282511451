.Flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SellItem {
  width: calc(100vw - 30px);
  margin-bottom: 1.5rem;
  border: solid 1px var(--2-background);

  @media screen and (min-width: 768px) {
    width: calc(50% - 0.75rem);
  }

  @media screen and (min-width: 1920px) {
    width: calc(33% - 0.5rem);
  }

  &__month {
    font-size: 14px;
    font-weight: 700;
    float: right;
  }

  &__header {
    display: flex;
    padding: 0.75rem 0.9375rem;
    flex-wrap: wrap;
    background: transparent
      linear-gradient(180deg, var(--background) 0%, var(--A-background) 100%) 0%
      0% no-repeat padding-box;
  }

  &__body {
    padding: 1.5rem 0 0 0;
    display: flex;
    flex-wrap: wrap;
    background: transparent
      linear-gradient(180deg, var(--background) 0%, var(--A-background) 100%) 0%
      0% no-repeat padding-box;

    span {
      font-size: 14px;
    }

    & > div {
      width: 100%;
      min-width: 0;
      text-align: center;

      @media screen and (min-width: 1440px) {
        width: 50%;
        text-align: left;
      }
    }
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__from-to {
    width: 100%;
    min-width: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    span {
      font-size: 18px;
      font-weight: 700;
    }

    @media screen and (min-width: 1440px) {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__graph {
    padding: 0 0.9375rem;
  }

  &__date {
    text-align: center;
    font-weight: 700;
    width: 100%;
    min-width: 0;

    @media screen and (min-width: 1440px) {
      width: 50%;
      text-align: right;
    }
  }

  &__currency {
    position: relative;
    padding: 0 0.9375rem;

    &::after {
      content: '';
      display: none;
      position: absolute;
      width: 2px;
      background-color: var(--2-background);
      top: -1.5rem;
      bottom: 0;
      right: 0;

      @media screen and (min-width: 1440px) {
        display: block;
      }
    }
  }

  &__footer {
    width: 100%;
    min-width: 0;
    padding: 0.75rem 0.9375rem 0.75rem 50%;
    background-color: var(--background);
    @media screen and (min-device-width: 992px) and (max-device-width: 1200px) {
      padding-left: 0.9375rem;
    }
    @media screen and (max-width: 450px) {
      padding-left: 0.9375rem;
    }
  }
  &__arrow {
    height: 14px;
    margin: auto 0;
  }

  &__offer {
    span:first-child {
      font-size: 1.125rem;
      font-weight: 700;
    }
    span:nth-child(2) {
      font-size: 1.5rem;
      font-weight: 700;
    }

    span:last-child {
      font-size: 1.125rem;
      font-weight: 700;
      display: inline-block;
      margin-left: 10px;
    }
  }

  hr {
    margin: 0;
    border-top: 1px solid var(--2-background);
  }
}
