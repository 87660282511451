.fullscreen {
  width: 100vw;
  height: 100%;
  background-color: hsl(0, 0%, 96%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.AuthForgotRoot.AuthLoginRoot .AuthLogin {
  width: 100%;

  &__inner {
    padding: 3rem 2rem 2rem;
  }
}

.AuthLoginRoot {
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, hsl(28, 82%, 50%) 0%, #ffbc00 100%) 0% 0% no-repeat padding-box;
}

.AuthLogin {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 0;
  background-color: var(--background);

  @media screen and (max-width: 650px) {
    height: 100%;
    width: 100%;
    min-width: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    // margin-bottom: 2rem;
  }

  &__form {
    display: none;

    &.AuthLogin__form--visible {
      display: block;
    }

    .AuthLogin__info {
      margin-bottom: 2rem;
    }
  }

  &__step-two {
    display: none;

    &.AuthLogin__step-two--visible {
      display: block;
    }
  }

  &__instruction {
    font-family: Robot, sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: var(--on-background);
    text-align: left;
    margin-bottom: 1.5rem;
  }

  &__sms-input {
    border: none;
    outline: none;
    padding: 0.5rem 1.375rem;
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
    min-width: 0;
    border-bottom: 1px solid var(--on-background);

    &[type='number'] {
      -moz-appearance: textfield !important;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__countdown {
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 0.875rem;
    color: var(--button-end-error);
  }

  &__inner {
    display: block;
    width: 90%;
    max-width: 450px;
    border-radius: 5px;
    box-shadow: 0px 0px 25px #ebebeb;
    padding: 2rem;
    margin-bottom: 1rem;

    .title {
      text-align: center;

    }

    .subtitle {
      text-align: center;
      margin-bottom: 3rem;
      color: grey;
      font-weight: 400;
    }
  }

  &__logo {
    width: 180px;

    // light logo
    .cls-1 {
      fill: url(#linear-gradient);
    }

    .cls-1,
    .cls-2,
    .cls-3 {
      stroke-width: 0px;
    }

    .cls-2 {
      fill: #ed7218;
    }

    .cls-3 {
      fill: #003b6f;
    }

    // dark logo
    .cls-1dark {
      fill: url(#linear-gradient);
    }

    .cls-1dark,
    .cls-2dark,
    .cls-3dark {
      stroke-width: 0px;
    }

    .cls-2dark {
      fill: #fff;
    }

    .cls-3dark {
      fill: #ed7218;
    }
  }

  .AuthLogin__input-wrapper {
    position: relative;
    margin-bottom: 2rem;
  }

  .AuthLogin__label {
    position: absolute;
    display: block;
    left: 0px;
    top: -23px;
    font-size: 0.9rem;
    color: var(--on-background);
    font-weight: 700;
    margin-bottom: 0;
  }

  .AuthLogin__showPassword {
    position: absolute;
    display: block;
    right: 12px;
    bottom: 10px;
    cursor: pointer;
  }

  &__input {
    display: block;
    width: 100%;
    min-width: 0;
    border: 2px solid #d5d5d585;
    border-radius: 5px;
    font-size: 1rem;
    line-height: 1.375rem;
    position: relative;
    padding: 0.5rem;
    letter-spacing: -0.01px;
    color: var(--on-background);
    background: none;

    &[type='number'] {
      -moz-appearance: textfield !important;
    }
  }

  &__forgotten {
    display: block;
    text-decoration: underline;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    color: var(--button-start-primary);
    margin-top: 0.5rem;

    &:hover {
      text-decoration: underline;
      color: var(--button-start-primary-hover);
    }
  }

  &__submit {
    border-radius: 100px;
    width: 100%;
    min-width: 0;
    padding: 0.75rem;
    background: var(--button-start-secondary) 0%;
    color: #000;
    text-transform: uppercase;
    border: none;
    font-weight: 500;
    line-height: 1;
    transition: 300ms ease-in-out background-color;
    margin-top: -1rem;
    position: relative;
    top: -10px;

    &:hover {
      cursor: pointer;
      background: var(--hover-link);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: grayscale(hsl(210, 100%, 23%));
    }
  }

  &__language {
    cursor: pointer;
    text-decoration: underline;
    border: none;
    background: transparent;
    box-shadow: none;
    color: var(--on-background);

  }
}

.registerOnline {
  font-size: 0.8rem;
  margin: 0;
}

.onlineRegisterLink {
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: underline;
  color: var(--button-start-primary);

  &:hover {
    color: var(--button-start-primary-hover);
  }
}

.AuthRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  background-color: #FFC332;

  @media screen and (max-width: 650px) {
    display: none;
  }

  &__box {
    background-color: var(--background);
    width: 90%;
    max-width: 550px;
    border-radius: 25px;
    padding: 1rem;
  }

  &__title {
    color: var(--on-background);
  }

  &__text {
    font-weight: 500;
    color: var(--on-background);
  }

  &__link {
    color: var(--button-start-primary);
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      color: var(--button-start-primary-hover);
    }
  }
}