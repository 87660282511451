.NewInstruction {
  &__header {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin: 30px 0;

    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }
}

.InstructionsDropdown {
  display: inline-block;
  line-height: 1.1875rem;
  padding: 8px 0 24px 0;
  .Dropdown__inner {
    padding: 17px 22px;
    .Dropdown__title {
      padding-right: 45px !important;
    }
  }
}
