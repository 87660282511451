.SimpleButton {
  &--regular {
    border-radius: 3px;
    background: transparent
      linear-gradient(
        180deg,
        var(--button-start-success) 0%,
        var(--button-end-success) 100%
      )
      0% 0% no-repeat padding-box;
    color: var(--on-primary);
    width: 100%;
    min-width: 0;
    height: 38px;
    text-transform: uppercase;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    font-weight: 700;

    &:focus,
    &:active {
      border: none;
      outline: none;
    }

    @media screen and (min-width: 768px) {
      max-width: 278px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &--reminder {
    position: relative;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
  }

  &__reminder {
    position: absolute;
    left: 0;
    right: 0;
    font-weight: 400;
    color: hsla(240, 0%, 48%, 1);
    font-size: 14px;
    text-transform: none;
    bottom: -22px;

    @media screen and (min-width: 768px) {
      bottom: -44px;
    }
  }

  &--plain {
    position: relative;
    color: var(--primary);
    padding-left: 12px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    background: transparent;
    border: none;
    outline: none;
    height: 38px;
    margin-top: 40px;

    &:hover {
      text-decoration: underline;
    }

    img {
      position: absolute;
      top: calc(50% - 5px);
      left: 0;
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
    }
  }

  &.SimpleButton--buy {
    background-color: var(--primary);
  }

  &.SimpleButton--primary {
    background: transparent
      linear-gradient(
        180deg,
        var(--button-start-primary) 0%,
        var(--button-end-primary) 100%
      )
      0% 0% no-repeat padding-box;
  }

  &.SimpleButton--plain.SimpleButton--buy {
    background-color: transparent;
    color: var(--primary);
  }

  &.SimpleButton--fullwidth {
    max-width: none;
  }

  &:disabled {
    background: hsl(0, 0%, 25%);
  }
}
