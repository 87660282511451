.Header {
  border-bottom: 1px solid var(--2-background);
  padding: 0.9375rem;
  display: none;
  background-color: var(--background);

  @media screen and (min-width: 768px) {
    padding: 0.90625rem 2.5rem;
  }

  &.Header--visible {
    display: block;
  }

  .top-bar {
    background-color: var(--background);
    padding: 0;

    @media screen and (min-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  .top-bar-right {
    // text-align: center;
    display: flex;
    @media screen and (min-width: 991px) {
      width: 470px;
      justify-content: space-between;
    }
    @media screen and (min-width: 551px) and (max-width: 991px) {
      padding-top: 20px;
      justify-content: center;
      gap: 15px;
    }
    @media screen and (max-width: 550px) {
      padding-top: 20px;
      justify-content: center;
      gap: 15px;
      flex-direction: column;
      align-items: center;
    }

    .video-button {
      a:hover {
        text-decoration: none;
        color: #fff;
      }
      img {
        margin-right: 0.5rem;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0.90625rem 2.5rem;
  }

  &__logo-image {
    height: 35px;

    // light logo
    .cls-1 {
      fill: url(#linear-gradient);
    }

    .cls-1,
    .cls-2,
    .cls-3 {
      stroke-width: 0px;
    }

    .cls-2 {
      fill: #ed7218;
    }

    .cls-3 {
      fill: #003b6f;
    }

    // dark logo
    .cls-1dark {
      fill: url(#linear-gradient);
    }

    .cls-1dark,
    .cls-2dark,
    .cls-3dark {
      stroke-width: 0px;
    }

    .cls-2dark {
      fill: #fff;
    }

    .cls-3dark {
      fill: #ed7218;
    }
  }
}
