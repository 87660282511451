.NewNotification {
  &__header {
    text-transform: uppercase;
    margin: 30px 0;
    font-size: 18px;
    line-height: 21px;
    text-align: center;

    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }
}
