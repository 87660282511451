body {
  background-color: var(--background);
  color: var(--on-background);
}

hr {
  height: 1px;
  background-color: var(--2-background);
  border: none;
}

.text-error {
  color: var(--error);
}

.text-on-primary {
  color: var(--on-background);
}

a {
  color: var(---link);
  &:hover {
    color: var(--hover-link);
  }
}

svg {
  pointer-events: none;
}

path {
  pointer-events: none;
}
