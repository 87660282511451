.NoWithdrawal,
.BuyNoWithdrawal {
  &__buttons {
    display: flex;
    flex-direction: row-reverse;
    min-width: 0;
    margin-top: 40px;
    margin-left: -20px;
    margin-right: 20px;

    > * {
      margin-left: 20px !important;
      margin-top: 20px !important;
    }

    @media screen and (min-width: 768px) {
      margin-right: 0;
    }
  }

  .SimpleButton--plain {
    order: 4;
    margin-right: auto;
  }

  .SimpleButton {
    margin-left: 20px;
  }

  .SimpleButton:nth-child(2) {
    order: 3;
  }

  .FormItem__row:not(:first-child) {
    width: 100%;
    min-width: 0;
  }

  .FormItem.FormItem--three-columns {
    .FormItem__row {
      @media screen and (min-width: 1440px) {
        justify-content: space-between;
      }

      &:last-child {
        @media screen and (min-width: 1440px) {
          justify-content: flex-start;
        }
      }
    }
  }
}

.BuyNoWithdrawal .FormItem {
  .input-plain,
  .input-super-plain,
  .input-date-big,
  .input-date,
  .input-select,
  .input-plus,
  .input-checkbox,
  .input-text {
    color: hsla(210, 100%, 22%, 1);
  }

  .input-upload {
    color: hsla(26, 92%, 52%, 1);
  }
}
