$colors: 'primary', 'on-primary';
$opacityArray: (
  '5': 0.5,
);

.Icon {
  @each $color in $colors {
    &--#{$color} {
      fill: var(--#{$color}-icon);
      &--stroke {
        stroke: var(--#{$color}-icon);
      }
    }
  }

  &--opacity {
    @each $opacityName, $opacity in $opacityArray {
      &-#{$opacityName} {
        opacity: #{$opacity};
      }
    }
  }
}
