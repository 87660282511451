.Loading {
  --mdc-theme-primary: hsl(27, 93%, 52%);

  width: 100%;
  min-width: 0;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  min-height: 50px;
  padding: 1rem;
}
