.Table {
  overflow: auto;

  table {
    width: 100%;
    min-width: 0;
    text-align: center;
  }

  table,
  thead,
  tbody,
  th {
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  tr {
    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

  thead {
    tr {
      @media screen and (max-width: 767px) {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }

    th {
      background-color: var(--top-color);
      color: var(--on-top-color);
    }
  }

  td {
    @media screen and (min-width: 768px) {
      padding: 1vh 1vw;
    }

    @media screen and (min-width: 1280px) {
      padding: 3vh 2vw;
    }

    @media screen and (max-width: 767px) {
      padding-left: 50%;
      position: relative;
      text-align: left;
      padding-bottom: 0;
      padding-top: 0;
      display: block;
    }
    &::before {
      @media screen and (max-width: 767px) {
        position: absolute;
        padding-right: 10px;
        content: attr(data-label);
        width: 45%;
        top: 0;
        left: 0;
        display: block;
        text-align: right;
        background-color: hsla(212, 100%, 94%, 1);
        color: hsla(210, 100%, 22%, 1);
        font-weight: 700;
        padding-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: 100%;
      }
    }
  }

  th {
    font-weight: 700;

    @media screen and (min-width: 768px) {
      padding: 1vh 1vw;
    }

    @media screen and (min-width: 1280px) {
      padding: 3vh 2vw;
    }
  }

  tbody {
    // background-color: hsl(0, 0%, 100%);

    tr {
      background: transparent
        linear-gradient(180deg, var(--background) 0%, var(--A-background) 100%)
        0% 0% no-repeat padding-box;

      // &:nth-child(2n) {
      //   background-color: hsl(0, 0%, 92.5%);
      // }
    }
  }

  &__no-notifications {
    background: transparent
      linear-gradient(180deg, var(--background) 0%, var(--A-background) 100%) 0%
      0% no-repeat padding-box;
    text-align: center;
  }

  &__actions__action {
    font-weight: 700;
    color: var(--primary);
  }
}
