.Aside {
  display: none;
  white-space: nowrap;
  overflow: auto;
  background-color: var(--background);

  &.Aside--visible {
    display: block;
    transition: 0.3s;

    @media screen and (max-width: 991px) {
      width: 0;
      padding: 0;
    }

    @media screen and (min-width: 992px) {
      width: 255px;
    }

    .drawer__content {
      opacity: 0;
      pointer-events: none;
      transition: 0.1s;

      @media screen and (min-width: 992px) {
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  &.Aside--visible.Aside--expanded {
    width: 80vw;
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: 0.3s;
    overflow-y: scroll;

    @media screen and (min-width: 768px) {
      width: 40vw;
    }

    .list {
      max-width: 100%;
      min-width: 0;
    }

    .contact-item {
      text-align: left;
      padding: 1.09375rem;
    }

    .drawer__content {
      opacity: 1;
      transition-duration: 0.3s;
      transition-property: opacity;
      transition-delay: 0.2s;
      pointer-events: all;
    }
  }
}

.overlay {
  position: fixed;
  transition: 0.5s;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: hsla(0, 0%, 0%, 0.4);
}
