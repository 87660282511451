.Tooltip {
  position: relative;
  display: inline-block;
  padding-left: 10px;

  &__text {
    width: 200px;
    background-color: var(--top-color);
    color: var(--info-text);
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;

    position: absolute;
    z-index: 1;

    margin-left: -65px;
    @media screen and (max-width: 730px) {
      margin-left: -125px;
    }
    @media screen and (max-width: 470px) {
      margin-left: -145px;
    }
    margin-top: 5px;
    left: -30px;

    font-size: 0.875rem;
    font-weight: 400;

    &__left {
      margin-left: -65px;
      left: 0;
    }
  }

  &__text:not(.Tooltip__text--visible) {
    visibility: hidden;
  }
}
