.Reminder {
  margin-left: 1rem;
  cursor: pointer;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &__text {
    display: block;
    font-weight: 400;
    background-color: var(--top-color);
    padding: 0.75rem;
    color: var(--on-top-color);
    font-size: 0.875rem;
    line-height: 1.5;
    z-index: 99;
    width: max-content;
    max-width: 95vw;

    @media screen and (min-width: 768px) {
      max-width: 450px;
    }
  }

  &__text:not(.Reminder__text--visible) {
    visibility: hidden;
  }

  &__arrow,
  &__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  &__arrow::before {
    content: '';
    transform: rotate(45deg);
    background-color: var(--top-color);
  }

  &__text[data-popper-placement^='top'] > &__arrow {
    bottom: -4px;
  }

  &__text[data-popper-placement^='bottom'] > &__arrow {
    top: -4px;
  }

  &__text[data-popper-placement^='left'] > &__arrow {
    right: -4px;
  }

  &__text[data-popper-placement^='right'] > &__arrow {
    left: -4px;
  }
}
