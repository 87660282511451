.Footer {
  grid-column: span 2;
  display: grid;
  grid-gap: 0.2rem;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid var(--2-background);
  background-color: var(--background);

  @media screen and (min-width: 768px) {
    padding: 1.5rem 2.5rem;
    grid-gap: 1rem;
  }

  &__cell {
    text-align: center;
    &.Footer__version-number,
    &.Footer__copyright,
    &.Footer__link {
      color: var(--on-A-background);
      font-size: 0.625rem;
      line-height: 0.75rem;
      font-weight: 400;

      @media screen and (min-width: 768px) {
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    &.Footer__version-number {
      text-align: left;
    }

    &.Footer__link {
      text-decoration: underline;
      text-align: center;
      color: var(--primary);
      line-height: 0.75rem;
      font-weight: 400;
      cursor: pointer;
    }

    &.Footer__copyright {
      text-align: end;
    }
  }
}
