.LightDarkButton {
  &-button {
    position: relative;
    width: 70px !important;
    img {
      vertical-align: top;
    }
  }
}

.LightDarkToggle-sun {
  display: flex;
  transform: translate(74%) !important;
 }

 .LightDarkToggle-moon {
  display: flex;
  transform: translate(-88%) !important;
  &::before {
    content: '';
    position: absolute;
    background-color: #bbb;
    width: 24px;
    height: 24px;
    top: 0px;
    left: 3px;
    border-radius: 50%;
    transition: left .5s ease-in-out;
  }
  &.LightDarkToggle-show-moon::before{
    left: 39px;
  }
}
