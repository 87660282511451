/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */

.FormItem {
  width: 100%;
  min-width: 0;
  max-width: 1089px;
  background-color: hsl(0, 0%, 100%);
  padding: 15px;

  @media screen and (min-width: 1440px) {
    padding: 52px 76px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__row {
    width: 100%;
    min-width: 0;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
    flex-wrap: wrap;

    @media screen and (min-width: 1440px) {
      margin-bottom: 60px;
    }
  }

  label {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    margin-bottom: 30px;

    @media screen and (min-width: 1440px) {
      margin-bottom: 6px;
    }
  }
}

.FormItem,
.GridFormItem,
.Form {
  .input-upload {
    width: 100%;
    min-width: 0;
    max-width: 300px;
    color: var(--primary);
    font-weight: 700;

    @media screen and (min-width: 768px) {
      width: 100%;
      min-width: 0;
    }

    label {
      width: 100%;
      min-width: 0;
      max-width: 300px;
      margin-top: 32px;
      cursor: pointer;
    }

    input {
      width: 0.1px;
      height: 0.1px;
      z-index: -1;
      position: relative;
      min-height: 42px;
    }
  }

  .input-super-plain {
    @media screen and (min-width: 768px) {
      position: relative;
      top: -50px;
    }
  }

  .input-checkbox {
    color: var(--primary);
    font-weight: 700;
  }

  .input-plain {
    color: var(--primary);
    font-weight: 700;
    text-align: center;
    width: 100%;
    min-width: 0;
    //max-width: 300px;

    span {
      color: var(--on-background);
      display: inline-block;
      margin-top: 14px;
    }

    @media screen and (min-width: 1440px) {
      text-align: center;
      width: 100%;
      min-width: 0;
    }
  }

  .input-select {
    color: var(--primary);
    font-weight: 700;
  }

  .input-date-big {
    color: var(--primary);
    font-weight: 700;

    input {
      max-width: 300px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      border: none;
      outline: none;
      display: inline-block;
      padding-left: 12px;
      padding-right: 12px;
      min-height: 42px;
    }
  }

  .input-text {
    color: var(--primary);
    font-weight: 700;

    @media screen and (min-width: 768px) {
      margin-left: 12px;
    }

    input,
    textarea {
      color: hsla(0, 0%, 0%, 0.56);
      box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.24);
      border: none;
      outline: none;
      padding: 10px 15px;
      margin-top: 6px;
      min-height: 42px;
    }
  }

  .input-plus {
    color: var(--primary);
    font-weight: 700;
    position: relative;

    input {
      margin-top: 6px;
      color: hsla(0, 0%, 0%, 0.56);
      padding: 12px 10px 8px 10px;
      border: none;
      outline: none;
      box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.24);
      max-width: 300px;
      min-height: 42px;
    }

    span {
      position: absolute;
      background-color: hsla(0, 0%, 96%, 1);
      font-weight: 400;
      color: hsl(0, 0%, 0%);
      height: 38px;
      width: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      right: 0;
      top: 20px;

      @media screen and (min-width: 1440px) {
        right: unset;
        left: 249px;
      }
    }
  }
}

.GridFormItem {
  // padding: 1.5rem;
  // background-color: var(--background);

  .input-upload {
    max-width: none;

    label {
      display: block;
      width: 100%;
      min-width: 0;
      max-width: none;
      margin-top: 0;
    }
  }

  .input-checkbox {
    input {
      margin-top: 0;
      display: block;
      width: 1rem;
      height: 1rem;
    }
  }

  .input-plain {
    max-width: none;

    span {
      margin-top: 0;
      display: block;
      width: 100%;
      min-width: 0;
    }
  }

  .input-date-big {
    input {
      max-width: none;
      display: block;
      width: 100%;
      min-width: 0;
    }
  }

  .input-text {
    @media screen and (min-width: 768px) {
      margin-left: 12px;
    }

    input {
      margin-top: 0;
      display: block;
      width: 100%;
      min-width: 0;
    }
  }

  .input-plus {
    input {
      margin-top: 0;
      max-width: none;
      display: block;
      width: 100%;
      min-width: 0;
    }

    span {
      display: flex;
      height: 100%;
      width: 52px;
      bottom: 0;
      right: 0;
      top: 0;

      @media screen and (min-width: 1440px) {
        right: 0;
        left: unset;
      }
    }
  }
}

.GridFormItem {
  &__form {
    &__container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      justify-content: space-evenly;
      align-items: flex-start;
      padding: 1.5rem 2.5rem;
      background: linear-gradient(0deg, #181511,  #201C17);

      @media screen and (min-width: 650px) {
        grid-template-columns: 1fr 1fr;
      }

      .form-label {
        width: 100%;
        min-width: 0 !important;
        display: block;
        margin: 0;
        padding: 0;

        &.rowSpan2 {
          grid-row: span 2;
        }

        &__inner {
          position: relative;
          margin-top: 0.75rem;
        }
      }
      &__firstRowSignificant {
        color: 'red'
      }
    }
  }
}

.greyLabel  .firstRow  .input-plain {
  color: #666 ;
  & .form-label__inner {
    font-size: 24px;
    margin-top: 0.3rem;
  }
}

.FormItem,
.GridFormItem,
.Form {
  .input-plus {
    input {
      &:active,
      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  .input-text input:active,
  .input-text input:focus {
    border: none;
    outline: none;
  }
}

.FormItem,
.GridFormItem,
.Form,
.AuthLogin {
  .input-choice {
    font-weight: 700;
    color: var(--primary);

    .form-label__inner {
      margin-left: -1rem;
      margin-top: -0.25rem;
    }

    &__choice {
      margin-left: 1rem;
      margin-top: 1rem;
      display: inline-block;

      &__span {
        margin-left: 0.5rem;
      }
    }
  }

  .input-split {
    .form-label__inner {
      display: grid;
      grid-template-columns: 2fr auto 1fr;
      grid-gap: 0.5rem;
      align-items: center;
    }
  }
}

.PaymentStepThree {
  .GridFormItem {
    &__form {
      &__container {
        @media screen and (min-width: 1280px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }
}

.SingleColumn {
  .GridFormItem {
    &__form {
      &__container {
        grid-template-columns: 1fr;
      }
    }
  }
}

/* stylelint-enable */
