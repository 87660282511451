.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(0, 0%, 0%, 0.3);
  z-index: 1000;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;

  &__close-button {
    position: absolute;
    top: 13px;
    right: 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  &__header {
    color: var(--top-color);
    border-bottom: 3px solid var(--2-background);
    padding: 51px 43px 15px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__body {
    position: absolute;
    background-color: var(--background);
    opacity: 1;
    padding: 71px 66px;
    min-width: min(75vw, 320px);
    max-width: 95vw;
    min-height: 0;
    max-height: 85vh;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 1rem 35px;
    }
  }

  &__flex-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: -64px;
    margin-top: -64px;
    padding: 64px;

    &__item {
      flex-shrink: 0;
      flex-grow: 0;
      margin-top: 64px;
      margin-left: 64px;

      &--250 {
        flex-basis: 27.36%;
      }
    }

    &--evenly {
      justify-content: space-evenly;
    }
  }

  &__div-grid {
    padding: 64px;
  }

  .SettingsIndex__nav-item {
    border-bottom: none;
    padding-top: 2rem;
  }

  .SettingsIndex__nav-item.SettingsIndex__nav-item--active {
    border-bottom: none;
    color: hsl(0, 0%, 100%);
    padding-top: 2rem;
    background-color: hsl(27, 93%, 52%);
  }
}

.Modal--Deposit {
  min-width: 569px;
  min-height: 619px;
  overflow-x: visible;
  @media screen and (max-width: 768px) {
    min-width: unset;
    min-height: unset;
  }
  .SettingsIndex__nav-item {
    border-bottom: 5px solid var(--primary);
  }
  .SettingsIndex__nav-item.SettingsIndex__nav-item--active {
    background-color: var(--primary);
  }
}

.Modal--Deposit,
.Modal--Withdrawal {
  .GridFormItem {
    padding: 0;
  }
}

.TradeModalComplaintForm {
  label {
    text-align: left;
    font: normal normal 700 14px/16px Roboto, sans-serif;
    letter-spacing: 0;
    color: hsl(0, 0%, 0%);
    opacity: 1;
  }

  textarea {
    width: 100%;
    min-width: 0;
    border: none;
    padding: 1em;
    margin-top: 10px;
    min-height: 149px;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    color: hsla(0, 0%, 0%, 0.56);
  }

  p {
    margin-top: 10px;
    text-align: right;
    letter-spacing: 0;
    color: hsl(0, 0%, 0%);
    opacity: 0.56;
  }
}
