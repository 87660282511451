.Modal {
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &--sms {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (min-width: 960px) {
      white-space: nowrap;
    }

    label {
      color: var(--primary);
      font-weight: 700;
      flex-grow: 1;

      @media screen and (min-width: 768px) {
        margin-right: 5%;
      }

      input {
        max-width: 445px;
        display: block;
        border: none;
        box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.24);
        padding: 11px 22px;
        width: 100%;
        min-width: 0;
      }

      .Countdown {
        color: var(--on-background);
        font-weight: 400;
        display: block;
        margin-top: 6px;
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    &--blue label {
      color: hsla(210, 100%, 22%, 1);
    }
  }
  
  &:hover {
    .Modal--createAccountInfo {
      color: #fff;
    }
  }

  &--createAccountInfo, &--amlMessage {
    text-align: center;

    &__link {
      color: var(--primary);
    }

    &__icon {
      margin-right: 12px;
    }
  }

  &--createTemplate,
  &--createAccount {
    .input-text {
      @media screen and (min-width: 768px) {
        width: 100%;
        min-width: 0;
      }
    }
    .input-select {
      @media screen and (min-width: 768px) {
        width: 100%;
        min-width: 0;
      }
    }
  }

  &--Graph__flex-container {
    display: flex;
    flex-direction: row;

    @media screen and (min-width: 992px) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &--Graph-container {
    position: relative;
    width: 100%;
    min-width: 0;
    
    @media screen and (min-width: 768px) {
      width: 500px;
    }
  }

  &--Graph__label {
    font-weight: 700;
    margin-bottom: 20px;
    align-self: center;
    color: var(--top-color);
    font-size: 24px;
    margin-bottom: 20px;

    @media screen and (min-width: 992px) {
      align-self: right;
    }
  }

  &--Graph__value {
    font-weight: 700;
    display: inline-block;
    margin-bottom: 20px;
    align-self: center;

    @media screen and (min-width: 992px) {
      margin-right: 25px;
      margin-bottom: 0;
      align-self: right;
    }
  }

  &--Graph {
    &__range-select {
      margin-top: 1em;
      margin-bottom: 1em;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media screen and (min-width: 992px) {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
      }

      &__label {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-end;
        align-items: center;
        grid-gap: 0.5em;
      }

      &__select {
        background: hsla(0, 0%, 100%, 1) 0% 0% no-repeat padding-box;
        box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.05);
        border: 1px solid hsla(0, 0%, 87%, 1);
        padding: 7px 13px;
      }
    }
  }

  .header-box {
    .header-label {
      p {
        margin-bottom: 5px;
        color: hsla(0, 0%, 61%, 1);
      }
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-gap: 1em;
      button {
        margin-bottom: 0;
        padding: 3px 15px;
  background-color: transparent;
  color:#fff;
  border-color: #fff;
  &.active {
    color: var(--primary);
    border-color: var(--primary);
  
  }
}
    }
  }

  .decimal {
    font-size: 24px;
    line-height: 21px;
    display: inline-block;
    margin-right: 6px;
  }

  .currency {
    color: hsla(0, 0%, 61%, 1);
  }

  .Dropdown {
    @media screen and (min-width: 992px) {
      width: 100%;
      min-width: 0;
    }
  }
}

.Modal__body--wide {
  max-width: 909px;
  width: 909px;
}

.Modal__body--no-padding {
  padding: 0;
}

.Modal--Withdrawal .form-label.input-plain {
  text-align: left;
}

.Modal--Deposit .form-label.input-plain {
  text-align: left;
}

.Modal--Withdrawal__actions {
  display: flex;
  justify-content: center;
}

.Modal--trade {
  &__data {
    &__title {
      font-weight: 700;
      font-size: 14px;
      color: var(--primary);
    }

    &__content {
      font-weight: 400;
      font-size: 18px;
      margin-top: 10px;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      font-weight: 700;
      font-size: 16px;
      color: var(--primary);
      margin-top: 14px;
    }
  }
}
