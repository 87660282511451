.Payment,
.BuyPayment,
.NewNotification,
.NewInstruction,
.NewTemplate,
.NewBankAccount,
.EditBankAccount,
.EditTemplate {
  &__buttons {
    display: flex;
    min-width: 0;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-top: 40px;
    margin-right: 20px;

    > * {
      margin-left: 20px !important;
      margin-top: 20px !important;
    }

    @media screen and (min-width: 768px) {
      margin-right: 0;
    }

    @media screen and (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }

  .SimpleButton--plain {
    order: 4;
    margin-top: 16px;

    @media screen and (min-width: 768px) {
      margin-right: auto;
    }
  }

  .SimpleButton {
    margin-top: 16px;
    @media screen and (min-width: 768px) {
      margin-left: 10px;
    }
  }

  .SimpleButton:nth-child(2) {
    order: 3;
  }

  .FormItem__row:not(:first-child) {
    width: 100%;
    min-width: 0;
  }

  .FormItem.FormItem--three-columns {
    .FormItem__row {
      justify-content: space-between;
      @media screen and (min-width: 1440px) {
        justify-content: space-between;
      }

      &:last-child {
        @media screen and (min-width: 1440px) {
          justify-content: flex-start;
        }
      }
    }
  }
}

.NewNotification,
.Modal--Deposit,
.BuyPayment,
.NewInstruction,
.NewTemplate,
.NewBankAccount,
.EditBankAccount,
.EditTemplate,
.Modal--createTemplate--blue,
.AuthLogin {
  .input-choice,
  .input-plain,
  .input-super-plain,
  .input-date-big,
  .input-date,
  .input-select,
  .input-plus,
  .input-checkbox,
  .input-upload,
  .input-text {
    color: var(--primary);
  }
}

.NewNotification,
.NewInstruction,
.NewTemplate,
.NewBankAccount,
.EditBankAccount,
.EditTemplate {
  margin-top: 30px;

  &__buttons {
    @media screen and (min-width: 768px) {
      margin-top: 60px;
    }
  }
}
