.Breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 1.5625rem;
  color: hsl(0, 0%, 62%);
  justify-content: center;

  @media screen and (min-width: 992px) {
    justify-content: flex-start;
  }

  &__balance-container {
    flex: 1 0 auto;
    text-align: right;
  }

  &__title {
    display: inline-block;
    margin-right: 10px;
    color: var(on-A-background);
    font-weight: 400;
    font-size: 14px;

    @media screen and (min-width: 768px) {
      margin-right: 40px;
    }
  }

  &__balance {
    color: var(--on-background);
    font-size: 24px;
    display: inline-block;
    margin-right: 6px;
  }

  &__currency {
    color: var(--on-background);
    text-transform: uppercase;
  }

  &__arrow {
    position: absolute;
    left: 0;
    fill: var(--top-color);
  }

  &__item {
    position: relative;
    padding: 0 13px 0 17px;
    color: var(--top-color);
    opacity: 50%;

    &:first-child {
      padding-left: 0;
      .Breadcrumbs__arrow {
        display: none;
      }
    }

    &:not(:last-child) {
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    &:last-of-type,
    &--last {
      opacity: 100%;
    }
  }

  & + hr {
    color: hsl(240, 5%, 91%);
    margin: 0 -0.9375rem 1.5625rem -0.9375rem;

    @media screen and (min-width: 768px) {
      margin: 0 -3.75rem 1.5625rem -3.65rem;
    }
  }
}
