.ValidationErrors {
  text-align: left;
  margin-bottom: 1rem;

  &__error {
    display: inline-block;
    font-size: 0.9rem;
    color: hsl(0, 100%, 50%);
    padding-right: 0.5em;
  }
}
