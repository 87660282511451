.UserIcon {
  cursor: pointer;
  line-height: 29px;
  position: relative;

  &__username {
    display: inline-block;
    padding-left: 10px;
    padding-right: 15px;
  }

  &__arrow {
    transform: rotate(90deg);
  }
}
