$colors: 'success', 'error', 'info';

.Toast {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  width: 370px;

  @each $color in $colors {
    &--type-#{$color} {
      background-color: rgb(var(--#{$color}-toast));
      border: 1px solid rgb(var(--#{$color}-toast));
    }
  }

  &__close {
    img {
      float: right;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      scale: (1);
      padding: 5px 0 15px 15px;
      margin: 0 !important;
      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__icon {
    margin-right: 12px;
  }
  &__title {
    font-weight: 700;
    line-height: 22px;
  }
  &__content {
    padding-top: 0.5rem;
  }
}

.ToastRegion {
  position: relative;
  z-index: 1001;

  &__body {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
