.Index {
  background-color: var(--A-background);
  height: 100%;
  width: 100%;
  min-width: 0;

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;

    &.Index__list--options {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 700;
      padding: 0 1.5rem;
      color: var(--on-A-background);
    }
  }

  &__item--small {
    width: 50%;
    @media screen and (min-width: 1250px) {
      width: 25%;
    }
  }

  &__item--large {
    display: none;

    @media screen and (min-width: 1250px) {
      width: 50%;
      display: block;
    }
  }

  &__headline {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 700;
    border-bottom: 1px solid hsl(0, 0%, 88%);
    margin-bottom: 0;
    padding: 0 0.9375rem 1.5625rem 0.9375rem;

    @media screen and (min-width: 768px) {
      padding-left: 3.75rem;
      padding-bottom: 1.5625rem;
    }
  }

  &__inner {
    padding: 0.9375rem 0.9375rem;
    @media screen and (min-width: 768px) {
      padding: 1.5625rem 3.75rem;
    }
  }
}
