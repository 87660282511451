.contact-item {
  display: block;
  margin-top: auto;
  margin-bottom: 0;
  text-align: center;

  &__phone-wrapper {
    margin-bottom: 10px;
  }

  &__phone-number {
    display: inline-block;
    margin-left: 0.75rem;
    color: var(--top-color);
    cursor: pointer;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.25rem;
  }

  &__label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    display: inline-block;
    margin-bottom: 10px;
  }

  &__user-image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 100px;
  }
}
